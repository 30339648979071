import "./style.scss";
import React, { useEffect } from 'react';
import { Routes } from "./components/routes/Routes";
import { Toaster } from "react-hot-toast";
function App() {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'logout') {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = process.env.REACT_APP_KEYCLOACK_POST_LOGOUT;
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    <>
      <Toaster position="top-right" reverseOrder={false}/>
      <Routes/>
    </>
  );
}

export default App;
