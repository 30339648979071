import { useRoutes } from "react-router-dom";
import React, { Suspense } from "react";
// Lazy load the components
const UseAuthRoute = React.lazy(() => import("../../helper/ProtectedRoute")
.then(module => ({ default: module.UseAuthRoute })));
const Ondemand = React.lazy(() => import("../../pages/Others/Ondemand"));
const Login = React.lazy(() => import("../../pages/login"));
const Admin = React.lazy(() => import("../sidebar/Admin"));
const SlaPage = React.lazy(() => import("../../pages/Others/SlaPage"));
const Callback = React.lazy(() => import("../../helper/Callback"));
const Consigments = React.lazy(() => import("../../pages/Others/Print"));
const AdditionalInfo = React.lazy(() => import("../../pages/Others/Additional"));
const Sendsms = React.lazy(() => import("../../pages/Others/Sendsms"));
const ZidProducts = React.lazy(() => import("../../pages/Zid/ZidProducts"));
const ZidMerchants = React.lazy(() => import("../../pages/Zid/ZidMerchants"));
const ZidOrders = React.lazy(() => import("../../pages/Zid/ZidOrders"));
const Express = React.lazy(() => import("../../pages/Others/express"));
const Management = React.lazy(() => import("../../pages/Others/management"));
const DetailedExpress = React.lazy(() => import("../../pages/BusinessLines/detailedExpress"));
const DetailedRole = React.lazy(() => import("../../pages/BusinessLines/DetailedRole"));
const Addresses = React.lazy(() => import("../../pages/Configrations/Addresses/Addresses"));
const SortingMachine = React.lazy(() => import("../../pages/Middlemile/SortingMachine"));
const Groups = React.lazy(() => import("../../pages/IAM/Groups"));
const Users = React.lazy(() => import("../../pages/IAM/Users"));
const Roles = React.lazy(() => import("../../pages/IAM/Roles"));
const DetailedManagement = React.lazy(() => import("../../pages/BusinessLines/DetailedManagement"));
const DetailedUser = React.lazy(() => import("../../pages/BusinessLines/DetailedUser"));
const DetailedOrder = React.lazy(() => import("../../pages/BusinessLines/DetailedOrder"));
const JobOrders = React.lazy(() => import("../../pages/Others/JobOrders"));
const Operations = React.lazy(() => import("../../pages/Others/Operations"));
const NoContent = React.lazy(() => import("../../pages/NoContent"));
const ThirdpartyConfig = React.lazy(() => import("../../pages/Thirdparty/Configrations"));
const Events = React.lazy(() => import("../../pages/Thirdparty/events"));
const Orders = React.lazy(() => import("../../pages/Thirdparty/orders"));
const Bags = React.lazy(() => import("../../pages/Middlemile/Bags"));
const Ratelimiter = React.lazy(() => import("../../pages/Invoices/ratelimiter"));
const AddressMapping = React.lazy(() => import("../../pages/Configrations/AddressMappings/AddressMapping"));
const Zones = React.lazy(() => import("../../pages/Configrations/Zones/zones"));
const Customers = React.lazy(() => import("../../pages/Crm/Customers/Customers"));
const Employees = React.lazy(() => import("../../pages/Crm/Employees/Employees"));
const Payroll = React.lazy(() => import("../../pages/Crm/Payrollcompany/Payrollcompany"));
const CustomerAdd = React.lazy(() => import("../../pages/Crm/Customers/CustomerAdd"));
const Invoices = React.lazy(() => import("../../pages/Invoices/Invoices"));
const Currency = React.lazy(() => import("../../pages/Configrations/Currency/Currency"));
const Hub = React.lazy(() => import("../../pages/Assetmanagement/Hubs/Hub"));
const EmployeeAdd = React.lazy(() => import("../../pages/Crm/Employees/EmployeeAdd"));
const Products = React.lazy(() => import("../../pages/Masters/Product/Products"));
const Rates = React.lazy(() => import("../../pages/Masters/Rates/Rates"));
const ProdcutAdd = React.lazy(() => import("../../pages/Masters/Product/ProductAdd"));
const HubsAdd = React.lazy(() => import("../../pages/Assetmanagement/Hubs/HubsAdd"));
const Pickups = React.lazy(() => import("../../pages/Operations/Pickup/Pickups"));
const Reasons = React.lazy(() => import("../../pages/Operations/Reasons/Reasons"));
const OpsSortingMachine = React.lazy(() => import("../../pages/Operations/SortingMachine/SortingMachine"));
const ConsignmentOrders = React.lazy(() => import("../../pages/Consignments/ConsignmentOrders"));
const OrderAdd = React.lazy(() => import("../../pages/Consignments/OrderAdd"));
const Tiers = React.lazy(() => import("../../pages/Configrations/Tiers/Tiers"));
const Tierodpair = React.lazy(() => import("../../pages/Configrations/Tiers/Tierodpair"));
const ConsignmentsPickup = React.lazy(() => import("../../pages/Operations/Pickup/Consignment"));
const Vehicle = React.lazy(() => import("../../pages/Assetmanagement/Vehicle/Vehicle"));
const VehicleRoute = React.lazy(() => import("../../pages/Operations/Routes/Routes"));
const Couriers = React.lazy(() => import("../../pages/Operations/Couriers/Courier"));
const Weight = React.lazy(() => import("../../pages/Operations/Weight/Weight"));
const Consignments = React.lazy(() => import("../../pages/Operations/ConsignmentOps/Consignments"));
const OrderDetailsAdd = React.lazy(() => import("../../pages/Consignments/OrderDetailsAdd"));
const Linehauls = React.lazy(() => import("../../pages/Operations/Linehaul/Linehauls"));
const DriverAdd = React.lazy(() => import("../../pages/Operations/Linehaul/Driver/DriverEditAdd"));
const Acl = React.lazy(() => import("../../pages/Operations/Acl/Acl"));
const AclView = React.lazy(() => import("../../pages/Operations/Acl/AclView"));
const TripView = React.lazy(() => import("../../pages/Operations/Linehaul/Trip/TripView"));
const LastmileView = React.lazy(() => import("../../pages/Operations/LastMile/LastMileView"));
const Sequence = React.lazy(() => import("../../pages/Consignments/Sequence"));
const SmsView = React.lazy(() => import("../../pages/Notifications/SmsView"));
const Ledger = React.lazy(() => import("../../pages/Invoices/CashDebriefing/Ledger"));
const CashTabs = React.lazy(() => import("../../pages/Invoices/CashDebriefing/CashTabs"));
const Settlement = React.lazy(() => import("../../pages/Invoices/CashDebriefing/Settlement"));
const Rto = React.lazy(() => import("../../pages/Operations/Rto/Rto"));
const Deliveryflow = React.lazy(() => import("../../pages/Operations/DeliveryFlow/DeliveryFlow"));
const DeliveryFlowAdd = React.lazy(() => import("../../pages/Operations/DeliveryFlow/DeliveryFlowAdd"));
const ShortAddress = React.lazy(() => import("../../pages/Configrations/ShortAddress/ShortAddress"));
const Localizations = React.lazy(() => import("../../pages/Settings/Localization"));
const Systems = React.lazy(() => import("../../pages/Settings/System"));
const Resources = React.lazy(() => import("../../pages/Settings/Resources"));
// Add fallback UI while loading the components
const LazyFallback = () => <div>Loading...</div>;
export const Routes = () => {
  const routes = useRoutes([
    {
      path: "/auth_callback",
      element: (
        <Suspense fallback={<LazyFallback />}>
            <Callback />
        </Suspense>
      ),
    },
    // {
    //   path: "/",
    //   element: <Navigate to="/login" />,
    // },
    {
      path: "/",
      element: (
        <Suspense fallback={<LazyFallback />}>
            <Login />
        </Suspense>
      ),
    },
    {
      path: "/no-content",
      element: (
        <Suspense fallback={<LazyFallback />}>
            <NoContent />
        </Suspense>
      ),
    },
    {
      element: (
        <Suspense fallback={<LazyFallback />}>
          <UseAuthRoute>
            <Admin />
          </UseAuthRoute>
        </Suspense>
      ),
      children: [
        {
          path: "/ondemand",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Ondemand />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/products/sla",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <SlaPage />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/consignments/print",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Consigments />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/consignments/additional-info",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <AdditionalInfo />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/tools/send-sms",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Sendsms />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/integrations/zid/products",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ZidProducts />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/integrations/zid/merchants",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ZidMerchants />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/integrations/zid/orders",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ZidOrders />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/iam/roles",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Roles />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/iam/roles/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DetailedRole />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/iam/groups",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Groups />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/iam/groups/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Groups />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/iam/users",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Users />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/iam/users/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DetailedUser />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/middlemile/sortingmachine/config",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <SortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/middlemile/sortingmachine/config/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <SortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/middlemile/sortingmachine/agent",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <SortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/middlemile/sortingmachine/machinescan",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <SortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/middlemile/bags",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Bags />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/middlemile/bags/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Bags />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/customers",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Customers />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/customers/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <CustomerAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/customers/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <CustomerAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/employees/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <EmployeeAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/employees/:id",
          element: (
            <UseAuthRoute>
              <EmployeeAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/masters/products",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Products />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/masters/products/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ProdcutAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/masters/products/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ProdcutAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/assets/hubs/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <HubsAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/assets/hubs/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <HubsAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/consignments",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Consignments />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/linehauls",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Linehauls />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/linehauls/driver/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DriverAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/linehauls/trip/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <TripView />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/lastmile",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <LastmileView />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/pickups",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Pickups />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/pickups/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ConsignmentsPickup />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/pickups/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Pickups />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/reasons",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Reasons />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/sortingmachine/config",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <OpsSortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/sortingmachine/config/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <OpsSortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/sortingmachine/agent",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <OpsSortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/sortingmachine/machinescan",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <OpsSortingMachine />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/deliveryflow",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Deliveryflow />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/deliveryflow/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DeliveryFlowAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/deliveryflow/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DeliveryFlowAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/consignments/orders",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ConsignmentOrders />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/consignments/orders/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <OrderAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/consignments/orders/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <OrderDetailsAdd />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/consignments/sequence",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Sequence />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/masters/rates",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Rates />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/tiers",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Tiers />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/tiers/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Tierodpair />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/finance/invoices",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Invoices />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/finance/cashdebriefing",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Ledger />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/finance/cashdebriefing/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <CashTabs />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/finance/cashdebriefing/settle/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Settlement />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/rto",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Rto />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/assets/hubs",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Hub />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/assets/vehicles",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Vehicle />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/routes",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <VehicleRoute />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/couriers",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Couriers />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/weighing",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Weight />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/consolidations",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Acl />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/ops/consolidations/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <AclView />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/employees",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Employees />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/crm/payrollcompany",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Payroll />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/addresses",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Addresses />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/addresses/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Addresses />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/addresses/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Addresses />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/zones",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Zones />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/addressmapping",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <AddressMapping />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/shortaddress",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ShortAddress />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/settings/localization",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Localizations />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/settings/system",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Systems />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/settings/resources",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Resources />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/configs/currency",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Currency />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/configurations",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ThirdpartyConfig />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/configurations/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ThirdpartyConfig />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/configurations/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <ThirdpartyConfig />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/orders",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Orders />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/orders/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Orders />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/orders/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Orders />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/events",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Events />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/events/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Events />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/thirdparty/events/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Events />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/notifications/sms",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <SmsView />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/projects/addon/orders",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <JobOrders />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/projects/addon/orders/add",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DetailedOrder />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/projects/addon/orders/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <DetailedOrder />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/projects/addon/operations",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Operations />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/finance/charges",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
                <Ratelimiter title="CHARGES"/>
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/express",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
              <Express title="EXPRESS"/>
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/invoice/:name/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
              <DetailedExpress/>
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/warehouse",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
              <Express title="WARE_HOUSE"/>
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/freightforwarding",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
               <Express title="FREIGHT_FORWARDING"/>
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/landfreight",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
              <Express title="LAND_FREIGHT" />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/customermanagement",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
              <Management />
              </UseAuthRoute>
            </Suspense>
          ),
        },
        {
          path: "/customermanagement/:id",
          element: (
            <Suspense fallback={<LazyFallback />}>
              <UseAuthRoute>
              <DetailedManagement />
              </UseAuthRoute>
            </Suspense>
          ),
        }
      ],
    },
  ]);
  return <>{routes}</>;
};
